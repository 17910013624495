<template>
<div>
  <v-card elevation="0">
      <v-card-text>
        <v-card outlined>
          <v-simple-table fixed-header height="350px" id="tableXls" ref="tableXls">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Sopt Name</th>
                  <th class="text-left">Category</th>
                  <th class="text-left">Activity Date</th>
                  <th class="text-left">Order Date</th>
                  <th class="text-left">Duration</th>
                  <th class="text-left">Order Number</th>
                  <th class="text-left">Qty</th>
                  <th class="text-left">Unit Price</th>
                  <th class="text-left">Sub Total</th>
                  <th class="text-left">Options</th>
                  <th class="text-left">Total Sales</th>
                  <th class="text-left">Total Cost</th>
                  <th class="text-left">Cust ID</th>
                  <th class="text-left">Customer</th>
                </tr>
              </thead>
              <tbody>
              <tr v-for="(doc, idx) in docs" :key="idx">
                  <td v-for="(val, key) in doc" :key="key">{{val}}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-btn class=" white--text" color="green" depressed @click="export2xls">Export to XLS</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="white--text" color="blue" depressed @click="initialize">Refresh</v-btn>
      </v-card-actions>
  </v-card>
</div>
</template>

<script>
import moment from 'moment'
import { utils, writeFileXLSX } from 'xlsx';

  // const short = require('short-uuid');
  export default {
    data: () => ({
      docs: [],
    }),

    computed: {

    },

    watch: {

    },

    created () {
      this.initialize()
    },
    filters: {
        moment: function (date) {
            if (!date || date=='undefined') return ''
            return moment(date).format('D-MMM-YYYY HH:mm:ss');
        }
    },

    methods: {
      async initialize () {
        const payload = {}
        this.docs = await this.$store.dispatch('reports/getPostObjs', {purl:'reports/getsales', pload:payload})
        console.log(this.docs)
      },

      export2xls() {
        const tableDom = document.getElementById('tableXls').childNodes[0].childNodes[0]
        console.log(tableDom)
          const wb = utils.table_to_book(tableDom)
          writeFileXLSX(wb, "rawdata-adventuraja.xlsx")

      },
      
    },
  }
</script>