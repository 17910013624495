<template>
<div>
  <v-card elevation="0">
      <v-card-text>
        <v-select placeholder="Select Report" :items="reports" item-text="name" item-value="id" v-model="reportId" outlined dense style="width:300px" class="ml-5"></v-select>
        <report-dau v-if="reportId=='dau'"></report-dau>
        <report-mau v-if="reportId=='mau'"></report-mau>
        <report-sales v-if="reportId=='sales'"></report-sales>
      </v-card-text>
  </v-card>
</div>
</template>

<script>
import moment from 'moment'
import ReportDau from '../components/ReportDau.vue';
import ReportMau from '../components/ReportMau.vue';
import ReportSales from '../components/ReportSales.vue';
export default 
{
  components: { 
    ReportDau,
    ReportMau ,
    ReportSales
  },
  data: () => ({
    reports: [
      {id:'dau', name:'Daily Active Users'},
      {id:'mau', name:'Monthly Active Users'},
      {id:'sales', name:'Sales Report'}
    ],
    reportId: ''
  }),

  computed: {

  },

  watch: {

  },

  created () {
  },
  filters: {
      moment: function (date) {
          if (!date || date=='undefined') return ''
          return moment(date).format('D-MMM-YYYY HH:mm:ss');
      }
  },

  methods: {
    
  },
}
</script>